@charset "utf-8";

$primary: #866892;
@import "../node_modules/bulma/bulma.sass";

.App {
  /*text-align: center;*/
}

.b-list .columns {
  margin: 0;
  padding: 0;
}

.b-list .column {
  padding: .25em .5em;
}

.b-list .columns:not(:last-child) {
  margin-bottom: 0;
}

/*.b-list .panel-block:nth-child(2n) {*/
/*    background-color: #fafafa;*/
/*}*/

.b-list .th.panel-block {
  background-color: #ffffff;
}

.b-list .overdue {
  background-color: gray;
}

.b-list .unstarted {
  background-color: lightgoldenrodyellow;
}

/*.history {*/
/*    !*position: absolute;*!*/
/*    !*top: 3rem;*!*/
/*    max-width: 100%;*/
/*    !*right: 0;*!*/
/*    !*z-index: 3;*!*/
/*    !*background-color: white;*!*/
/*}*/

/*.history:not(:hover) {*/
/*    right: -13rem;*/
/*}*/

.history .ea-content {
  /*max-width: 100%;*/
  max-height: 5em;
  overflow: auto;
}

.min-5 {
  min-width: 5em
}
